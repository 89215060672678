import React, { Component } from "react";
import Routes from "./containers/router/router_cont";
class App extends Component {
  render() {
    return (
      <Routes />
    );
  }
}

export default App;
