export const SET_EMAIL = "SET_EMAIL";
export const SET_PASSWORD = "SET_PASSWORD";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_ALL_EVENTS = "SET_ALL_EVENTS";
export const SET_DETAILS = "SET_DETAILS";
export const firebase_config = {
  apiKey: "AIzaSyBeB8wbzoRXSi2eOiIvEsl2aOCUVvte3ao",
  authDomain: "panda-machine-care.firebaseapp.com",
  databaseURL: "https://panda-machine-care.firebaseio.com",
  projectId: "panda-machine-care",
  storageBucket: "panda-machine-care.appspot.com",
  messagingSenderId: "488143287252",
  appId: "1:488143287252:web:7757fa476ce1369047dc41",
  measurementId: "G-33EQSGC49C"
};